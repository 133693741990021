<template>
  <v-app>
    <AppHeader/>
    <v-content>
      <v-slide-x-transition mode="out-in">
        <keep-alive>
          <router-view/>
        </keep-alive>
      </v-slide-x-transition>
    </v-content>
    <AxiosErrors/>
    <InitApp/>
  </v-app>
</template>

<script>
import AxiosErrors from './components/AxiosErrors'
import AppHeader from './components/AppHeader'
import InitApp from './components/InitApp'

export default {
  name: 'App',
  components: {
    AppHeader,
    AxiosErrors,
    InitApp
  }
}
</script>
