/**
 * Set fetch dates in store
 * @param  {Object} state   Vuex State
 * @param  {Array} payload Array with the fetch dates in YYYY-MM-DD format
 */
const SET_FETCH_DATES = (state, payload) => {
  state.fetchDates = payload
}
/**
 * Set bibstem data in store
 * @param  {Object} state   Vuex State
 * @param  {Object} payload Object in the form of {<journal code>:<journal full name>}
 */
const SET_BIBSTEM = (state, payload) => {
  state.bibstem = payload
}
/**
 * Set range of max and min years in store
 * @param  {Object} state   Vuex State
 * @param  {Object} payload Object in the form of {minYear: 1900, maxYear:2015}
 */
const SET_YEAR_RANGE = (state, payload) => {
  state.yearRange = payload
}

export default {
  SET_FETCH_DATES,
  SET_BIBSTEM,
  SET_YEAR_RANGE
}
