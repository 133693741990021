<template>
  <v-layout align-end justify-start row class="plotsubnav">
    <v-btn
      flat
      small
      color="accent"
      v-on:click="$store.dispatch('plot/addPlot')"
      ><v-icon small>add</v-icon>New Plot</v-btn>
  </v-layout>
</template>

<script>
export default {

}
</script>

<style scoped>
.plotsubnav {
  overflow: hidden;
  display: flex;
  height: 100%;
}

.plotsubnav > button {
  margin: 0;
}
</style>
