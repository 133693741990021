<template>
  <v-form v-model="valid"
    ref="newPlot">
    <v-container grid-list-md>
      <v-layout row wrap>
        <v-flex :[responsive.split]="true">
          <v-select
            v-if="Object.keys(availableFetchDates).length"
            :items="availableFetchDates"
            height="25"
            v-model="fetchDate"
            label="Fetch Date"
            :rules="ruleSelect"
            />
        </v-flex>
        <v-flex :[responsive.split]="true">
          <v-select
            v-if="Object.keys(availableJournals).length"
            :items="Object.keys(availableJournals)"
            v-model="journals"
            attach
            height="25"
            chips
            multiple
            :rules="ruleJournals"
            label="Journals"
            >
            <template v-slot:prepend-item>
              <v-btn
               @click="selectAllJournals(true)"
              >
                Select All
              </v-btn>
              <v-btn
               @click="selectAllJournals(false)"
              >
                Select None
              </v-btn>
            </template>
            <template v-slot:selection="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span class="selected-journal" v-on="on">{{ item }}</span>
                </template>
                {{ availableJournals[item] }}
              </v-tooltip>
            </template>
          </v-select>
        </v-flex>
        <v-flex xs12>
          <v-radio-group
            v-if="responsive.yearLimitBoxes"
            v-model="startYear"
            row
            label="Limit starting year">
            <v-radio
              :label="String(availableYearRange[0])"
              :value="availableYearRange[0]">
            </v-radio>
            <v-radio
              v-for="year in startYearSteps"
              :key="year"
              :label="String(year)"
              :value="year">
            </v-radio>
          </v-radio-group>
          <v-select
            v-if="!responsive.yearLimitBoxes"
            :items="startYearSteps"
            height="25"
            v-model="startYear"
            label="Limit starting year"
            />
        </v-flex>
        <v-flex :[responsive.year_digits]="true">
          <v-text-field
            label="From year"
            v-model="yearRange[0]"
            type="number"
            ></v-text-field>
        </v-flex>
        <v-flex :[responsive.year_slider]="true">
          <v-range-slider
            class="px-3"
            v-model="yearRange"
            :ticks="showTicks"
            :min="startYear"
            :max="availableYearRange[1]"
            label="Year Range"
            ></v-range-slider>
        </v-flex>
        <v-flex :[responsive.year_digits]="true">
          <v-text-field
            label="To year"
            v-model="yearRange[1]"
            type="number"
            ></v-text-field>
        </v-flex>
        <v-flex :[responsive.split]="true">
          <v-select
            item-text="name"
            item-value="type"
            height="25"
            :items="availablePlotTypes"
            v-model="plotType"
            :rules="ruleSelect"
            label="Plot Type"
            >
              <template v-slot:item="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <div class="select-item" v-on="on"><span>{{ item['name'] }}</span></div>
                  </template>
                  <span v-html="plotTypeDescriptions[item['type']]"></span>
                </v-tooltip>
              </template>
          </v-select>
        </v-flex>
        <v-flex :[responsive.split]="true">
          <v-btn
            color="success"
            @click="plot"
            >Plot</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { plotTypesAsArray, plotTypeDescriptions } from '@/plotTypes'
import EventBus from '@/plugins/eventbus'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      valid: null,
      journals: [],
      fetchDate: null,
      yearRange: [0, 1],
      plotType: '',
      availablePlotTypes: [],
      plotTypeDescriptions: {},
      ruleSelect: [v => !!v || 'Select a value'],
      ruleJournals: [v => !!v.length || 'Select a journal'],
      responsive: {
        year_digits: 'xs1',
        year_slider: 'xs10',
        split: 'xs6',
        yearLimitBoxes: true
      },
      startYear: 2000
    }
  },
  computed: {
    availableJournals () {
      return this.$store.getters['runtime/getBibstem']
    },
    availableFetchDates () {
      return this.$store.getters['runtime/getFetchDates']
    },
    availableYearRange () {
      return Object.values(this.$store.getters['runtime/getYearRange'])
    },
    startYearSteps () {
      const result = []
      const possibleYears = this.availableYearRange
      for (let i = parseInt(possibleYears[0]); i <= parseInt(possibleYears[1]); i++) {
        if (i % 50 === 0) {
          result.push(i)
        }
      }
      return result
    },
    showTicks () {
      return (this.availableYearRange[1] - this.startYear < 70)
    },
    ...mapGetters({
      getStyle: 'plot/getStyleById'
    })
  },
  props: {
    plotid: String
  },
  watch: {
    startYear (newVal) {
      this.setStyle('startYear', newVal)
    },
    availableYearRange (newVal) {
      this.setYearRange(newVal)
    }
  },
  methods: {
    plot () {
      if (this.$refs.newPlot.validate()) {
        this.$store.dispatch('plot/setPlotProperty', {
          plotid: this.plotid,
          mutations: {
            FETCH_DATE: this.fetchDate,
            RANGE: { from: this.yearRange[0], until: this.yearRange[1] },
            TYPE: this.plotType,
            VISIBLE_JOURNALS: this.journals
          }
        })
        EventBus.$emit('plot-' + this.plotid)
        this.$emit('plot')
      }
    },
    selectAllJournals (all) {
      if (all) {
        this.journals = Object.keys(this.availableJournals)
      } else {
        this.journals = []
      }
    },
    responsiveForm (data) {
      if (data.newWPx < 500) {
        this.responsive.year_digits = 'xs12'
        this.responsive.year_slider = 'xs12'
        this.responsive.split = 'xs12'
        this.responsive.yearLimitBoxes = false
      }
      if (data.newWPx > 500 && data.newWPx < 800) {
        this.responsive.year_digits = 'xs2'
        this.responsive.year_slider = 'xs8'
        this.responsive.split = 'xs6'
        this.responsive.yearLimitBoxes = true
      }
      if (data.newWPx > 800) {
        this.responsive.year_digits = 'xs1'
        this.responsive.year_slider = 'xs10'
        this.responsive.split = 'xs6'
      }
    },
    /**
     * Store style settings to vuex
     * @param {string} styleName Name of the style, used as key
     * @param {misc} newVal The value to be stored
     */
    setStyle (styleName, newVal) {
      const style = this.getStyle(this.plotid)
      style[styleName] = newVal
      this.$store.dispatch('plot/setPlotProperty', {
        plotid: this.plotid,
        mutations: {
          STYLE: style
        }
      })
    },
    setYearRange (newVal) {
      // set range
      if (this.startYear > newVal[0]) {
        this.yearRange = [this.startYear, newVal[1]]
      } else {
        this.yearRange = [newVal[0], newVal[1]]
      }

      const range = this.$store.getters['plot/getRangeById'](this.plotid)
      if (range) {
        this.yearRange = [range.from, range.until]
      }
    }
  },
  created () {
    EventBus.$on('resize-' + this.plotid, this.responsiveForm)
  },
  beforeMount () {
    this.availablePlotTypes = plotTypesAsArray
    this.plotTypeDescriptions = plotTypeDescriptions

    // check if settings are already present in store and set controls
    const fetchDate = this.$store.getters['plot/getFetchDateById'](this.plotid)
    if (fetchDate) {
      this.fetchDate = fetchDate
    }
    const journals = this.$store.getters['plot/getVisibleJournalsById'](this.plotid)
    if (journals) {
      this.journals = journals
    }
    const type = this.$store.getters['plot/getTypeById'](this.plotid)
    if (type) {
      this.plotType = type
    }

    const style = this.getStyle(this.plotid)
    if (Object.keys(style).length) {
      if (style.startYear) {
        this.startYear = style.startYear
      }
    }

    this.setYearRange(this.availableYearRange)
  }
}
</script>

<style>
.selected-journal {
  margin: 0 5px;
  padding: 2px 7px;
  border: 1px solid #ccc;
  border-radius: 20px;
  font-size: 12px;
}
.select-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.select-item > span {
  display: inline;
  height: fit-content;
}
</style>
