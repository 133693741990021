<template>
  <div>
    <h2 class="font-weight-light">Application state</h2>
    <p>The application state stores your current session.</p>
    <v-dialog
      v-model="dialogReset"
      width="500"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          small
          color="warning"
          v-on="on">Reset Application</v-btn>
      </template>

      <v-card>
        <v-card-title
          class="headline warning font-weight-light"
          primary-title
        >
          Reset Application
        </v-card-title>

        <v-card-text>
          This will reset the application and all plots and settings will be deleted!
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="accent"
            flat
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            flat
            color="primary"
            @click="reset">
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogExport"
      width="600"
    >
      <template v-slot:activator="{ on }">
        <v-btn small color="secondary" v-on="on" @click="getState">Export</v-btn>
      </template>
       <v-card>
        <v-toolbar
          card
          >
          <v-toolbar-title class="font-weight-light">Export state</v-toolbar-title>
          <v-spacer/>
          <v-btn flat color="secondary" @click="copyToClipboard">Copy to clipboard<v-icon right>{{ copyIcon }}</v-icon></v-btn>
          <v-btn flat color="primary" @click="download">Download<v-icon right>mdi-file-download-outline</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-textarea box label="State" readonly auto-grow v-model="state"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn flat @click="dialogExport = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogImport"
      width="600"
    >
      <template v-slot:activator="{ on }">
    <v-btn small color="primary" v-on="on">Import</v-btn>
      </template>
       <v-card>
        <v-toolbar
          card
          >
          <v-toolbar-title class="font-weight-light">Import state</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-card-text>
          <p>Action</p>
          <v-radio-group v-model="replaceMerge" :column="false">
            <v-radio label="Merge" key="merge" value="merge"/>
            <v-radio label="Replace" key="replace" value="replace"/>
          </v-radio-group>
          <template v-if="replaceMerge==='merge'">
            How should settings like the plot colors be handled?
            <v-radio-group v-model="mergeOptions" :column="false">
              <v-radio label="Keep" key="keep" value="keep"/>
              <v-radio label="Replace" key="replace" value="replace"/>
            </v-radio-group>
          </template>
          <v-divider/>
          You can either select a file that was exported or paste state.
          <h3 class="font-weight-light">Upload file</h3>
          <label id="fileSelect">
            <span v-if="selectedFile">Selected file: "{{ selectedFile.name }}"</span>
            <span v-else>Select File</span>
            <input type="file" ref="fileupload" accept=".bajer" @change="handleFileChange" />
          </label>

          <h3 class="font-weight-light">Paste state</h3>
          <v-textarea
            box
            label="Paste state"
            auto-grow
            v-model="importState"
            :error="importStateError"
            :error-messages="importStateErrorMsg"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn flat @click="dialogImport = false">Cancel</v-btn>
          <v-btn flat color="primary" @click="startImport">Import</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import lomerge from 'lodash.merge'
import loUniqby from 'lodash.uniqby'

export default {
  data () {
    return {
      dialogReset: false,
      dialogExport: false,
      dialogImport: false,
      state: null,
      filetype: 'bajer',
      selectedFile: null,
      importState: '',
      importStateError: false,
      importStateErrorMsg: '',
      replaceMerge: 'merge',
      mergeOptions: 'keep',
      copyIcon: 'mdi-clipboard-outline'
    }
  },
  components: {
  },
  watch: {
    dialogExport () {
      this.copyIcon = 'mdi-clipboard-outline'
    },
    dialogImport () {
      this.importState = ''
    }
  },
  methods: {
    /**
     * Resets the stores state and restarts the application
     */
    reset () {
      localStorage.vuex = ''
      location.reload()
    },
    getState () {
      this.state = localStorage.vuex
    },
    copyToClipboard () {
      navigator.clipboard.writeText(this.state).then(() => {
        this.copyIcon = 'mdi-clipboard-check-outline'
      }, function () {
        this.copyIcon = 'mdi-clipboard-alert-outline'
        console.error('copy state to clipboard failed')
      })
    },
    download () {
      const data = new Blob([this.state])
      const a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(data, { type: JSON })
      a.download = 'state.bajer'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    handleFileChange (e) {
      this.selectedFile = e.target.files[0]
    },
    startImport () {
      /** Methods in Vuex good to know about:
        https://vuex.vuejs.org/api/#replacestate
        With this we can avoid a reload of the page as done in the previous
        protoype.
        It is also possible to decide to replace or merge states.
        How much custom handling of data is necessary in order to merge without
        conflicts needs to be checked. */

      if (!this.selectedFile && !this.importState) {
        this.importStateErrorMsg = 'Please select a file or paste a state here!'
        this.importStateError = true
        return
      }

      if (this.selectedFile) {
        const reader = new FileReader()
        reader.onload = file => this.doImport(JSON.parse(file.target.result))

        reader.readAsText(this.selectedFile)
      } else {
        this.doImport(JSON.parse(this.importState))
      }
    },
    /**
     * This will handle the actual import
     * The `runtime` part of the current running state will be copied to the
     * new state.
     *
     * Dependening if a merge or replacement of the current state is selected
     * different paths are taken.
     * @param  {Object} state State object
     */
    doImport (state) {
      // copy over runtime from current running state
      state.runtime = JSON.parse(JSON.stringify(this.$store.state.runtime))

      if (this.replaceMerge === 'replace') {
        this.$store.replaceState(state)
      } else if (this.replaceMerge === 'merge') {
        // Create new empty state object
        const newState = { plot: {}, settings: {}, runtime: {} }
        // copy runtime state to new state
        newState.runtime = state.runtime

        // merge plots
        lomerge(newState.plot, this.$store.state.plot, state.plot)

        // merge layout
        newState.settings.layout = loUniqby([...this.$store.state.settings.layout, ...state.settings.layout], 'i')

        // merge or overwrite other settings
        if (this.mergeOptions === 'keep') {
          newState.settings.plotColors = JSON.parse(JSON.stringify(this.$store.state.settings.plotColors))
        } else {
          newState.settings.plotColors = JSON.parse(JSON.stringify(state.settings.plotColors))
        }

        this.$store.replaceState(newState)
      }
      /** Apparently the store needs to be reminded that things changed in order for vuex-persistedstate getting triggered */
      this.$store.commit('runtime/SET_FETCH_DATES', state.runtime.fetchDates)

      this.cleanUp()
    },
    cleanUp () {
      this.selectedFile = null
      this.importState = null
      this.replaceMerge = 'merge'
      this.mergeOptions = 'keep'
      this.importStateError = false
      this.importStateErrorMsg = null
      this.dialogImport = false
    }
  }
}
</script>

<style>
#fileSelect {
  width: 100%;
  display: block;
  padding: 20px;
  text-align: center;
  vertical-align: center;
  background-color: rgba(0,0,0,0.05);
  border: 2px solid rgba(0,0,0,0.5);
  border-radius: 3px;
  transition: 0.3s ease-in-out;
}
#fileSelect:hover,
#fileSelect:focus {
  background-color: rgba(0,0,0,0.2);
  cursor: pointer;
}
#fileSelect input[type=file] {
  display: none;
}
</style>
