<template>
  <v-dialog v-model="initFailed" persistent max-width="600">
    <v-card>
      <v-card-text class="text-xs-center">
        <v-alert
          :value="true"
          color="error"
          icon="sentiment_very_dissatisfied"
          >
        <h1>Connection Error</h1>
        <p>The application was not able to connect to the database.</p>
        <p>Please reload this page and try again.</p>
        <p>If this Problem persists for longer please let someone responsible know.</p>
        <v-divider dark inset />
        <p>Could not load data from the database needed to initialize the application</p>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from '@/plugins/eventbus'
import JournalColors from '@/plotColors'

export default {
  data () {
    return {
      initFailed: false,
      fetchDates: {},
      bibstem: {},
      yearRange: {}
    }
  },
  methods: {
    listen () {
      this.initFailed = true
    }
  },
  created () {
    EventBus.$on('InitError', this.listen)

    // get basic needed Data from Api and store it
    this.$bajerApi.getFetchDates().then(value => {
      this.$store.commit('runtime/SET_FETCH_DATES', value.date)
    }).catch(function (error) {
      console.log(error)
      EventBus.$emit('InitError')
    })

    this.$bajerApi.getBibstem().then(value => {
      this.$store.commit('runtime/SET_BIBSTEM', value.bibstem)

      // Set plot colors if not set (first or fresh load after reset)
      if (!Object.keys(this.$store.getters['settings/getPlotColors']).length) {
        this.$store.commit('settings/SET_PLOT_COLORS', JournalColors(this.$store.getters['runtime/getBibstem']))
      }
    }).catch(function (error) {
      console.log(error)
      EventBus.$emit('InitError')
    })

    this.$bajerApi.getYearRange().then(value => {
      this.$store.commit('runtime/SET_YEAR_RANGE', value)
    }).catch(function (error) {
      console.log(error)
      EventBus.$emit('InitError')
    })
  }
}
</script>

<style>
</style>
