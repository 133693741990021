var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-xy-plot"},[(_vm.loading)?_c('v-layout',{attrs:{"align-center":"","justify-center":"","column":"","fill-height":""}},[_c('v-progress-circular',{attrs:{"width":7,"size":70,"color":"primary","indeterminate":""}}),_c('h3',{staticClass:"font-weight-light"},[_vm._v("Loading...")])],1):_vm._e(),(!_vm.loading)?_c('PlotActionToolbar',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-switch',_vm._g({staticClass:"plot-action-toolbar-toggle",attrs:{"height":1,"color":"accent","label":"y-log"},model:{value:(_vm.ylog),callback:function ($$v) {_vm.ylog=$$v},expression:"ylog"}},on))]}}],null,false,3400253270)},[_c('span',[_vm._v("Set y-Axis to logarithmic scaling")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-switch',_vm._g({staticClass:"plot-action-toolbar-toggle",attrs:{"height":1,"color":"accent","label":"Legend"},model:{value:(_vm.legend),callback:function ($$v) {_vm.legend=$$v},expression:"legend"}},on))]}}],null,false,3773646139)},[_c('span',[_vm._v("Show legend")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-switch',_vm._g({staticClass:"plot-action-toolbar-toggle",attrs:{"height":1,"color":"accent","label":"Title"},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}},on))]}}],null,false,348105627)},[_c('span',[_vm._v("Show title in the graph area")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"accent","flat":""},on:{"click":function($event){return _vm.download('csv')}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-delimited")]),_vm._v(" CSV ")],1)]}}],null,false,3300828213)},[_c('span',[_vm._v("Download data as CSV")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","color":"accent","flat":""},on:{"click":function($event){return _vm.download('json')}}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-json")]),_vm._v(" JSON ")],1)]}}],null,false,3514159571)},[_c('span',[_vm._v("Download data as JSON")])])],1):_vm._e(),_c('div',{staticClass:"default-xy-plot-plotlywrap",attrs:{"id":'plotly-' + _vm.plotid}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }