<template>
  <v-layout justify-end class="plot-action-toolbar">
    <slot></slot>
  </v-layout>
</template>

<script>
export default {
}
</script>

<style>
.plot-action-toolbar {
  border-bottom: 1px solid #ccc;
}
</style>
