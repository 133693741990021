<template>
  <div class="statusbar">
    <PlotStatusBarItem icon="timeline" v-if="plotType">{{ plotType }}</PlotStatusBarItem>
    <PlotStatusBarItem icon="mdi-download-network-outline" v-if="fetchDate">{{ fetchDate }}</PlotStatusBarItem>
    <PlotStatusBarItem icon="mdi-newspaper" v-if="Journals">{{ Journals }}</PlotStatusBarItem>
    <PlotStatusBarItem icon="mdi-calendar-range" v-if="yearRange">{{ yearRange }}</PlotStatusBarItem>
    <span class="placeholder">Nothing set</span>
  </div>
</template>

<script>
import plotTypes from '@/plotTypes'
import PlotStatusBarItem from './PlotStatusBarItem'

export default {
  components: {
    PlotStatusBarItem
  },
  props: {
    plotid: String
  },
  computed: {
    plotType () {
      return plotTypes[this.$store.getters['plot/getTypeById'](this.plotid)]
    },
    fetchDate () {
      return this.$store.getters['plot/getFetchDateById'](this.plotid)
    },
    Journals () {
      const journals = this.$store.getters['plot/getVisibleJournalsById'](this.plotid)

      if (journals) { return journals.join(' | ') }

      return false
    },
    yearRange () {
      const range = this.$store.getters['plot/getRangeById'](this.plotid)

      if (range) { return range.from + ' — ' + range.until }

      return false
    }
  }
}
</script>

<style scoped>
.statusbar {
  margin-top: auto;
  border-top: 1px solid #ccc;
  padding: 2px 20px 2px 5px;
  color: #666;
  background-color: #f5f5f5;
}

.placeholder {
  display: none;
}
.placeholder:only-child {
  display: inline-block;
}
</style>
