import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import plotModule from './plot/index'
import settingsModule from './settings/index'
import runtimeModule from './runtime/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    plot: plotModule,
    settings: settingsModule,
    runtime: runtimeModule
  },
  state: {

  },
  mutations: {

  },
  actions: {

  },
  plugins: [createPersistedState({ paths: ['plot', 'settings'] })]
})
