<template>
  <component v-bind:is="plotComponent" :plotid="plotid"></component>
</template>

<script>
import EventBus from '@/plugins/eventbus'
import { typeToPlotMapping } from '@/plotTypes'
import NotPlotting from './NotPlotting'
import DefaultXYPlot from './DefaultXYPlot'
import FirstAuthorCountries from './FirstAuthorCountries'
import BoxPlot from './BoxPlot'

export default {
  components: {
    NotPlotting,
    DefaultXYPlot,
    FirstAuthorCountries,
    BoxPlot
  },
  data () {
    return {
      plotComponent: 'NotPlotting'
    }
  },
  props: {
    plotid: String
  },
  methods: {
    startPlotting () {
      this.plotComponent = typeToPlotMapping[this.$store.getters['plot/getTypeById'](this.plotid)]
    }
  },
  created () {
    EventBus.$on('plot-' + this.plotid, this.startPlotting)

    // check if settings are already present in store and start plotting
    const fetchDate = this.$store.getters['plot/getFetchDateById'](this.plotid)
    const journals = this.$store.getters['plot/getVisibleJournalsById'](this.plotid)
    const range = this.$store.getters['plot/getRangeById'](this.plotid)
    const type = this.$store.getters['plot/getTypeById'](this.plotid)

    if (fetchDate && journals.length && Object.keys(range).length && type) {
      this.startPlotting()
    } else {
      this.$emit('goToSettings')
    }
  }
}
</script>
