const colorList = [ // standard plotly / d3 color scheme

  '#1f77b4', // muted blue
  '#ff7f0e', // safety orange
  '#2ca02c', // cooked asparagus green
  '#d62728', // brick red
  '#9467bd', // muted purple
  '#8c564b', // chestnut brown
  '#e377c2', // raspberry yogurt pink
  '#7f7f7f', // middle gray
  '#bcbd22', // curry yellow-green
  '#17becf' // blue-teal
]

/**
 * Assigns color from the default scale to journals
 * @param {Object} bibstem Object in the form of {<journal key>: <journal full name>}
 * @return {Object} Colors for each journal as the application needs it {<journal key>: <hex code>}
 */
const JournalColors = function (bibstem) {
  const colors = {}
  const journalKeys = Object.keys(bibstem)
  for (let i = 0; i < journalKeys.length; i++) {
    colors[journalKeys[i]] = colorList[i % colorList.length]
  }

  return colors
}

export default JournalColors

export {
  JournalColors,
  colorList
}
