// Type and human readable name
const plotTypes = {
  published: 'Papers published',
  cited: 'Citations',
  cites_to_pub: 'Citations normalized',
  all_papers_basic: 'Citations as box plot',
  citations_to_global: 'Journal citations to all citations',
  zero_cites: 'Zero citations',
  zero_cites_normalized: 'Zero citations normalized',
  first_author_per_country_per_year: 'First Authors Countries'
}

// Descriptions
const plotTypeDescriptions = {
  published: 'Plots the total amount of papers published per year',
  cited: 'Plots the total number of citations a journal got in a year',
  cites_to_pub: 'Plots the ratio of total citations to total published papers',
  citations_to_global: `Plots the yearly citations of a journal in relation to all other journals.<br>
                        (Journal citations / year ) / ( All citations / year )`,
  zero_cites: 'Plots the total amount of papers published that got zero citations',
  zero_cites_normalized: 'Plots the normalized ratio of papers that got zero citations to published papers',
  first_author_per_country_per_year: 'Plots the top contributing countries. (Based on affiliation)',
  all_papers_basic: `Plots the citations as boxplot, outliers are linked to their ADS entry<br>
                    <b>Using this plot will degrade the performance!</b>`
}

// Mapping of types to plotting component
const typeToPlotMapping = {
  published: 'DefaultXYPlot',
  cited: 'DefaultXYPlot',
  cites_to_pub: 'DefaultXYPlot',
  citations_to_global: 'DefaultXYPlot',
  zero_cites: 'DefaultXYPlot',
  zero_cites_normalized: 'DefaultXYPlot',
  first_author_per_country_per_year: 'FirstAuthorCountries',
  all_papers_basic: 'BoxPlot'
}

// Create array of objects used by vuetify selects
const plotTypesAsArray = []

for (const key in plotTypes) {
  plotTypesAsArray.push(
    { type: key, name: plotTypes[key] }
  )
}

export default plotTypes

export {
  plotTypesAsArray,
  plotTypeDescriptions,
  typeToPlotMapping
}
