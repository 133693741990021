<template>
  <span class="plotstatusbaritem"><v-icon small class="mr-1" v-if="icon">{{ icon }}</v-icon><slot></slot></span>
</template>

<script>
export default {
  props: {
    icon: String
  }
}
</script>

<style>
.plotstatusbaritem {
  display: inline-block;
  margin-right: 15px;
}
</style>
