const state = {
  title: {},
  description: {},
  fetch_date: {},
  range: {},
  type: {},
  visible_journals: {},
  style: {}
}

export default {
  state
}
