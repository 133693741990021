<template>
  <a class="color-block" ref="colorpicker"
      @click="toggle()">
    <span
      class="color"
      :style="{ 'background-color': color.hex }">
      <v-icon small :color="fontColor">keyboard_arrow_down</v-icon>
    </span>
    <v-slide-y-transition>
      <chrome-picker
        v-if="showPicker"
        v-model="color"
        class="picker"
        disableAlpha></chrome-picker>
    </v-slide-y-transition>
  </a>
</template>

<script>
import { Chrome } from 'vue-color'
export default {
  data () {
    return {
      color: { hex: this.value },
      fontColor: '#000',
      showPicker: false
    }
  },
  props: {
    value: String
  },
  components: {
    'chrome-picker': Chrome
  },
  watch: {
    color: {
      /**
       * Watches for color changes and calculates if the font color should be white or black
       * Taken from
       */
      handler: function () {
        this.fontColor = (
          parseInt(this.color.hex.slice(1, 3), 16) * 0.299 +
          parseInt(this.color.hex.slice(3, 5), 16) * 0.587 +
          parseInt(this.color.hex.slice(5, 7), 16) * 0.114) >
          149 ? '#000000' : '#FFFFFF'
      },
      deep: true
    },
    value (newVal) {
      this.color = { hex: newVal }
    }
  },
  methods: {
    show () {
      document.addEventListener('click', this.outsideClick)
      this.showPicker = true
    },
    hide () {
      document.removeEventListener('click', this.outsideClick)
      this.showPicker = false
      this.$emit('input', this.color.hex)
    },
    toggle () {
      (this.showPicker) ? this.hide() : this.show()
    },
    outsideClick (e) {
      const el = this.$refs.colorpicker
      const target = e.target
      if (el !== target && !el.contains(target)) {
        this.hide()
      }
    }
  }
}
</script>

<style>
.vc-chrome {
  font-family: Roboto, sans-serif !important;
}
.color-block {
  width: 28px;
  height: 28px;
  border: 1px solid #ccc;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}
.color-block > span {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-self: center;
  align-content: center;
}

.color-block .picker {
  position: absolute;
  left: -20px;
  top: 28px;
  z-index: 90;
}
</style>
