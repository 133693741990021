<template>
  <div class="default-xy-plot">
    <v-layout align-center justify-center column fill-height v-if="loading">
      <v-progress-circular
        :width="7"
        :size="70"
        color="primary"
        indeterminate />
      <h3 class="font-weight-light">Loading...</h3>
    </v-layout>
    <PlotActionToolbar v-if="!loading">
        <v-select
          class="style-select"
          item-text="name"
          item-value="type"
          v-model="plotStyleValue"
          :items="plotStyles"
          label="Style"
          height="20"
          ḧide-details
          color="accent"></v-select>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-switch
              v-on="on"
              :height="1"
              color="accent"
              v-model="ylog"
              label="y-log"
              class="plot-action-toolbar-toggle"
              ></v-switch>
          </template>
          <span>Set y-Axis to logarithmic scaling</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-switch
              v-on="on"
              :height="1"
              color="accent"
              v-model="legend"
              label="Legend"
              class="plot-action-toolbar-toggle"
              ></v-switch>
          </template>
          <span>Show legend</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-switch
              v-on="on"
              :height="1"
              color="accent"
              v-model="title"
              label="Title"
              class="plot-action-toolbar-toggle"
              ></v-switch>
          </template>
          <span>Show title in the graph area</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="accent"
              flat
              v-on="on"
              @click="download('csv')"
              >
              <v-icon left>mdi-file-delimited</v-icon>
              CSV
            </v-btn>
          </template>
          <span>Download data as CSV</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              color="accent"
              flat
              v-on="on"
              @click="download('json')"
              >
              <v-icon left>mdi-json</v-icon>
              JSON
            </v-btn>
          </template>
          <span>Download data as JSON</span>
        </v-tooltip>
      </PlotActionToolbar>
    <div :id="'plotly-' + plotid" class="default-xy-plot-plotlywrap"></div>
  </div>
</template>

<script>
import EventBus from '@/plugins/eventbus'
import plotTypes from '@/plotTypes'
import PlotActionToolbar from './PlotActionToolbar'
import { mapGetters } from 'vuex'
import Plotly from 'plotly.js'

Plotly.register([
  require('plotly.js/lib/groupby')
])

export default {
  components: {
    PlotActionToolbar
  },
  data () {
    return {
      plotData: null,
      loading: false,
      title: null,
      legend: true,
      ylog: false,
      plotStyles: [
        { name: 'Lines', type: 'lines' },
        { name: 'Lines & Markers', type: 'lines+markers' },
        { name: 'Lines stacked', type: 'linesstacked' },
        { name: 'Lines stacked %', type: 'linesstackednormalized' },
        { name: 'Bar', type: 'bar' },
        { name: 'Bar stacked', type: 'barstacked' }
      ],
      plotStyleValue: 'lines'
    }
  },
  props: {
    plotid: String
  },
  computed: {
    ...mapGetters({
      getTitle: 'plot/getTitleById',
      getFetchDate: 'plot/getFetchDateById',
      getRange: 'plot/getRangeById',
      getType: 'plot/getTypeById',
      getVisibleJournals: 'plot/getVisibleJournalsById',
      getStyle: 'plot/getStyleById',
      PlotColors: 'settings/getPlotColors'
    })
  },
  watch: {
    /**
     * Handle changes in the selected style of the plot
     * @param  {String} newVal Identifier of the style
     */
    plotStyleValue (newVal) {
      this.setStyle('plotStyleValue', this.plotStyleValue)
      if (this.plotData) {
        this.plot()
      }
    },
    /**
     * Handle show title toggler
     * @param {Boolean} newVal newVal of the toggle switch
     */
    title (newVal) {
      if (newVal) {
        this.title = this.getTitle(this.plotid)
      } else {
        this.title = null
      }
      this.setStyle('title', this.title)
      if (this.plotData) {
        this.plot()
      }
    },
    /**
     * Handle show legend toggler
     * @param {Boolean} newVal newVal of the toggle switch
     */
    legend (newVal) {
      this.setStyle('legend', newVal)
      if (this.plotData) {
        this.plot()
      }
    },
    /**
     * Handle yAxis logarithmic toggler
     * @param  {Boolean} newVal newVal of the toggle switch
     */
    ylog (newVal) {
      this.setStyle('ylog', newVal)
      if (this.plotData) {
        this.plot()
      }
    }
  },
  methods: {
    /** Fetch data to plot from the API endpoint */
    getData () {
      this.loading = true

      const range = this.getRange(this.plotid)
      this.$bajerApi.getPlot(
        this.getType(this.plotid),
        range.from,
        range.until,
        this.getVisibleJournals(this.plotid),
        this.getFetchDate(this.plotid)
      ).then(value => {
        this.plotData = value
        this.loading = false
        this.plot()
      }).catch(function (error) {
        console.log(error)
        EventBus.$emit('Error', error)
      })
    },
    /** Prepare and call plotly.js */
    plot () {
      let type = null
      let mode = null
      let barmode = null
      let stacked = null
      let norm = null

      switch (this.plotStyleValue) {
        case 'lines':
          type = 'scatter'
          mode = 'lines'
          break
        case 'lines+markers':
          type = 'scatter'
          mode = 'lines+markers'
          break
        case 'linesstacked':
          type = 'scatter'
          mode = 'lines'
          stacked = 'one'
          break
        case 'linesstackednormalized':
          type = 'scatter'
          mode = 'lines'
          stacked = 'one'
          norm = 'percent'
          break
        case 'bar':
          type = 'bar'
          break
        case 'barstacked':
          type = 'bar'
          barmode = 'stack'
          break
      }

      const data = [{
        type,
        x: this.plotData.xAxis,
        y: this.plotData.yAxis,
        mode,
        stackgroup: stacked,
        groupnorm: norm,
        transforms: [{
          type: 'groupby',
          groups: this.plotData.bibstem,
          styles: this.PlotColors
        }]
      }]
      const layout = {
        title: this.title,
        barmode,
        xaxis: { title: 'Years' },
        yaxis: {
          title: plotTypes[this.getType(this.plotid)],
          zeroline: true,
          showline: true,
          rangemode: 'tozero'
        },
        margin: {
          t: 10,
          l: 60,
          r: 10,
          b: 35
        },
        showlegend: this.legend
      }
      const cssStyles = getComputedStyle(document.getElementById('plotly-' + this.plotid))
      const config = {
        modeBarButtonsToRemove: ['toImage', 'sendDataToCloud'],
        modeBarButtonsToAdd: [
          {
            name: 'Download as SVG',
            icon: Plotly.Icons.camera,
            click: (gd) => {
              Plotly.downloadImage(gd, {
                format: 'svg',
                width: cssStyles.width.slice(0, -2),
                height: cssStyles.height.slice(0, -2),
                filename: this.getTitle(this.plotid)
              })
            }
          },
          {
            name: 'Download as PNG',
            icon: Plotly.Icons.camera,
            click: (gd) => {
              Plotly.downloadImage(gd, {
                format: 'png',
                width: cssStyles.width.slice(0, -2),
                height: cssStyles.height.slice(0, -2),
                filename: this.getTitle(this.plotid)
              })
            }
          }
        ]
      }

      if (this.title) { layout.margin.t = 35 }
      if (this.ylog) { layout.yaxis.type = 'log' }

      Plotly.newPlot('plotly-' + this.plotid, data, layout, config)
    },
    /**
     * Tell plotly.js to redraw
     */
    resize () {
      const d3 = Plotly.d3
      const gd = d3.select('#plotly-' + this.plotid)
      Plotly.Plots.resize(gd.node())
    },
    /**
     * Store style settings to vuex
     * @param {string} styleName Name of the style, used as key
     * @param {misc} newVal The value to be stored
     */
    setStyle (styleName, newVal) {
      const style = this.getStyle(this.plotid)
      style[styleName] = newVal
      this.$store.dispatch('plot/setPlotProperty', {
        plotid: this.plotid,
        mutations: {
          STYLE: style
        }
      })
    },
    /**
     * Prepare blob and download it
     * @param  {string} dataType `json` or `csv`
     */
    download (dataType) {
      let data = null
      let extension = null
      let mime = null

      if (dataType === 'json') {
        data = new Blob([JSON.stringify(this.plotData)])
        extension = 'json'
        mime = 'text/JSON'
      }

      if (dataType === 'csv') {
        extension = 'csv'
        mime = 'text/CSV'

        const delimiter = ','
        const newline = '\n'
        let count = 0

        let csv = ''

        const keys = Object.keys(this.plotData)

        csv += keys.join(delimiter)
        csv += newline

        this.plotData[keys[0]].forEach(function (value, index) {
          count = 0
          keys.forEach(function (key) {
            if (count > 0) { csv += delimiter }

            csv += this.plotData[key][index]
            count++
          }.bind(this))
          csv += newline
        }.bind(this))

        data = new Blob([csv])
      }

      const a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(data, { type: mime })
      a.download = this.getTitle(this.plotid) + '.' + extension
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  },
  created () {
    EventBus.$on('plot-' + this.plotid, this.getData)
    EventBus.$on('resize-' + this.plotid, this.resize)

    // initialize stored style values
    const style = this.getStyle(this.plotid)
    if (Object.keys(style).length) {
      (style.legend === false) ? this.legend = false : this.legend = true
      this.title = this.getStyle(this.plotid).title || null
      this.plotStyleValue = this.getStyle(this.plotid).plotStyleValue || 'lines'
      this.ylog = this.getStyle(this.plotid).ylog || false
    }
    this.getData()
  },
  activated () {
    // When newly created after a reload or import the data still needs to be fetched at this point
    if (this.plotData) {
      this.plot()
    }
  }
}
</script>

<style>
.default-xy-plot {
  height: inherit;
}
.default-xy-plot-plotlywrap {
  height: calc(100% - 44px);
}
.style-select {
  width: 160px;
  flex: none;
}
.style-select .v-text-field__details {
  display: none;
}
.style-select .v-input__slot {
  margin-bottom: 0;
}

.style-select .v-select__selections > input {
  display: none;
}
.plot-action-toolbar-toggle {
  flex: none;
  margin-right: 10px;
}
</style>
