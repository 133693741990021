import axios from 'axios'
import EventBus from '@/plugins/eventbus'

const getBaseURL = async function () {
  // check and remove known routes
  let location = window.location.toString()
  // TODO: link this directly to router to avoid having two definitions
  const endpoints = ['preferences', 'about']
  for (const item in endpoints) {
    const reg = '/' + endpoints[item] + '$'
    const result = location.match(reg)
    if (result) {
      location = location.substring(0, result.index)
    }
  }
  return fetch(location + '/baseurl.conf').then(response => response.text())
}

const api = axios.create()

api.interceptors.request.use(async config => {
  config.baseURL = await getBaseURL()
  return config
}, error => Promise.reject(error))

api.interceptors.response.use(
  function (response) { return response },
  function (error) {
    console.error(error)
    // handle error
    if (error) {
      EventBus.$emit('AxiosError', error)
    }
    return Promise.reject(error)
  }
)

/*
* Plugin to have one instance of the api available everywhere
*/
export default {
  install (Vue, options) {
    Vue.prototype.$bajerApi = {
      // export the api object to be able to add interceptors with UI interaction
      api,
      /**
       * Retrieves all fetch dates from the API
       * @return {object} Fetch Dates
       */
      getFetchDates: () => {
        return api.get('fetch_dates').then(response => {
          return response.data
        })
      },
      /**
       * Retrieves the possible year range to plot
       * @return {object} Possible min and max years
       */
      getYearRange: () => {
        return api.get('year_range').then(response => {
          return response.data
        })
      },
      /**
       * Retrieves all available journals
       * @return {object} Journals available
       */
      getBibstem: () => {
        return api.get('bibstem').then(response => {
          return response.data
        })
      },

      /**
       * API call to fetch plot data
       * Since every plot needs to provide the same parameters only one method
       * is used. In case more parameters are needed they can be passed using
       * the `additional` parameter.
       * @param  {string} type       Type of plot, name of the API endpoint
       * @param  {number} minYear    Year when to start the plot
       * @param  {number} maxYear    Year until to plot
       * @param  {array} bibstem     Journals to plot
       * @param  {string} fetchDate  The fetch date to plot data from
       * @param  {object} additional More parameters in the form of { name: value }
       * @return {object}            Object with the returned data
       */
      getPlot: (type, minYear, maxYear, bibstem, fetchDate, additional = null) => {
        const params = {
          minYear,
          maxYear,
          bibstem: bibstem.join(),
          fetchDate
        }

        if (additional) {
          for (const [key, value] of Object.entries(additional)) {
            params[key] = value
          }
        }

        return api.get(type, { params }).then(response => {
          return response.data
        })
      }
    }
  }
}
