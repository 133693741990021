<template>
  <v-dialog
    v-model="dialog"
    width="250"
    lazy
    @keydown.enter="remove"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon small
        v-on="on"
      >
        <v-icon height="40px">close</v-icon>
      </v-btn>
    </template>

    <v-card
      >
      <v-card-title
        class="headline warning font-weight-light"
        primary-title
      >
        Delete Plot
      </v-card-title>

      <v-card-text>
        Do you want to delete this plot?
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          @click="dialog=false"
        >
          No
        </v-btn>
        <v-btn
          flat
          @click="this.remove"
        >
          Yes
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: function () {
    return {
      dialog: null
    }
  },
  methods: {
    remove () {
      this.dialog = false
      this.$emit('remove')
    }
  }
}
</script>

<style>

</style>
