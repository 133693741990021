<template>
  <v-layout align-center justify-center row fill-height>
    <p>There is nothing to plot yet. Please go to <b>Settings</b>.</p>
  </v-layout>
</template>

<script>
export default {
}
</script>
