<template>
  <div id="header">
      <img src="@/assets/logo.svg"/>
      <h1>The<b>BAJer</b></h1>
      <span>Bibliometrics for Astronomical Journals</span>
    <div id="nav">
      <v-btn
        flat
        small
        class="font-weight-light"
        v-on:click="$router.push({name: 'home'})">
        <v-icon class="font-weight-light" small>mdi-home-outline</v-icon>Start
      </v-btn>
      <v-btn
        flat
        small
        class="font-weight-light"
        v-on:click="$router.push({name: 'preferences'})">
        <v-icon class="font-weight-light" small>settings</v-icon>Preferences
      </v-btn>
      <v-btn
        flat
        small
        class="font-weight-light"
        v-on:click="$router.push({name: 'about'})">
        <v-icon class="font-weight-light" small>mdi-information-outline</v-icon>About
      </v-btn>
    </div>
    <div id="subnav">
      <router-view name="subnav"/>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#title, #nav {
  border-bottom: 1px solid #ccc;
}

#header{
  display: grid;
  grid-template-columns: 61px 260px;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "icon title nav"
    "icon subtitle subnav";
  border-bottom: 1px solid #ccc;
  height: 60px;
}
#header> img {
  grid-area: icon;
  height: 48px;
  align-self: center;
  justify-self: center;
}
#header > h1 {
  grid-area: title;
  align-self: end;
  font-weight: 300;
  font-size: 23px;
  line-height: 1;
  > b {
    font-weight: 600;
  }
}
#header > span {
  grid-area: subtitle;
  align-self: center;
}

#header > #nav {
  grid-area: nav;
  display: flex;
  justify-content: flex-start;
  align-self: end;
  > button {
    margin: 0;
  }
}

#header > #subnav {
  border-left: 1px solid #ccc;
  box-shadow: inset 5px 5px 11px -10px rgba(0,0,0,0.46);
  > a {
    display: block;
  }
}
</style>
