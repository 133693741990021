<template>
  <v-snackbar
    v-model="snackbar"
    top
    right
    auto-height
    color="error"
    :timeout="0"
  >
    <div>
      <h3>Problem fetching data:</h3>
      <span>{{ message }}</span>
    </div>
    <v-btn
      flat
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
import EventBus from '@/plugins/eventbus'

export default {
  data () {
    return {
      snackbar: false,
      message: ''
    }
  },
  methods: {
    listen: function (error) {
      this.message = error
      this.snackbar = true
    }
  },
  created: function () {
    EventBus.$on('AxiosError', this.listen)
  }
}
</script>
