const getLayout = (state) => state.layout

const getPlotColors = (state) => {
  const colors = []
  const keys = Object.keys(state.plotColors)
  for (const key in keys) {
    colors.push({ target: keys[key], value: { marker: { color: state.plotColors[keys[key]] } } })
  }
  return colors
}

const getPlotColorsPlain = (state) => state.plotColors

export default {
  getLayout,
  getPlotColorsPlain,
  getPlotColors
}
