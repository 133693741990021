<template>
  <div>
    <Welcome v-if="!havePlots"/>
    <Mainpage v-else/>
  </div>
</template>

<script>
import Welcome from '@/components/Welcome'
import Mainpage from '@/components/MainPage'

export default {
  components: {
    Welcome,
    Mainpage
  },
  computed: {
    havePlots: function () {
      return this.$store.getters['plot/getNumberOfPlots']
    }
  }
}
</script>
