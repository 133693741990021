// import { fetchProducts } from '@/api'
const uuidv4 = require('uuid/v4')

const addPlot = (context) => {
  const plotid = uuidv4()
  context.commit('ADD_PLOT', plotid)
  context.commit('settings/ADD_PLOT', plotid, { root: true })
}

const removePlot = (context, plotid) => {
  context.commit('REMOVE_PLOT', plotid)
  context.commit('settings/REMOVE_PLOT', plotid, { root: true })
}

const setPlotProperty = (context, payload) => {
  /*
    Sets the property of a plot. Payload needs to be in the form of
    ```
    {
      plotid: <plotid>,
      mutations: {
        <mutation name>: <mutation payload>
      }
    }
    ```
    it can contain multiple mutations.
  */
  const { plotid, mutations } = payload

  for (const mutation in mutations) {
    try {
      context.commit(
        'SET_' + mutation.toUpperCase(),
        {
          plotid: plotid,
          [mutation.toLowerCase()]: mutations[mutation]
        })
    } catch (error) {
      console.error(error)
    }
  }
}

export default {
  addPlot,
  removePlot,
  setPlotProperty
}
