<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    lazy
    @keydown.esc="cancel"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">

      <v-btn
        icon
        small
        v-on="on"
      >
        <v-icon small>edit</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="headline grey lighten-2 font-weight-light"
        primary-title
      >
        Edit {{ title }}
      </v-card-title>

      <v-card-text>
        <v-text-field v-if="!multiline" ref="editfield" v-model="dataModel"></v-text-field>
        <v-textarea v-else ref="editfield" outline autofocus :label="title" v-model="dataModel"></v-textarea>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          color="primary"
          @click="this.cancel"
        >
          Cancel
        </v-btn>
        <v-btn
          flat
          color="primary"
          @click="this.save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      dataModel: ''
    }
  },
  props: {
    title: String,
    data: String,
    multiline: Boolean,
    width: {
      type: Number,
      default: 300
    }
  },
  beforeMount () {
    this.dataModel = this.data
  },
  updated () {
    this.$nextTick(this.$refs.editfield.focus)
  },
  methods: {
    save () {
      this.$emit('save', this.dataModel)
      this.dialog = false
    },
    cancel () {
      this.dialog = false
    }
  },
  watch: {
    dialog (newVal) {
      if (!newVal) { this.dataModel = this.data }
    }
  }

}
</script>

<style>

</style>
