<template>
  <v-container fluid>
    <grid-layout
      :layout.sync="layout"
      :col-num="20"
      :row-height="60"
      :is-draggable="true"
      :is-resizable="true"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
      :autoSize="true"
      :responsive="false"
      :breakpoints="{ lg: 1892, md: 1248, sm: 960, xs: 600, xxs: 0 }"
      :cols="{ lg: 124, md: 20, sm: 12, xs: 8, xxs: 4 }"
      @layout-updated="layoutUpdatedEvent"
    >
      <grid-item v-for="item in layout"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        :minW="4"
        :minH="3"
        dragAllowFrom=".v-toolbar"
        @resized="callPlotResize"
        >
        <plot-container
          :plotid="item.i"
          />
      </grid-item>
    </grid-layout>
  </v-container>
</template>

<script>
import EventBus from '@/plugins/eventbus'
import VueGridLayout from 'vue-grid-layout'
import PlotContainer from '@/components/plot/PlotContainer'

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    PlotContainer
  },
  data: function () {
    return {
      layout: []
    }
  },
  created () {
    this.layout = JSON.parse(JSON.stringify(this.layoutFromStore))
  },
  methods: {
    layoutUpdatedEvent (newLayout) {
      const filtered = newLayout.map((item) => { return { x: item.x, y: item.y, w: item.w, h: item.h, i: item.i } })
      this.$store.commit('settings/SET_LAYOUT', filtered)
    },
    callPlotResize (id, newH, newW, newHPx, newWPx) {
      EventBus.$emit('resize-' + id, { newH, newW, newHPx, newWPx })
    }
  },
  watch: {
    layoutFromStore: function (val) {
      if (val) {
        this.layout = JSON.parse(JSON.stringify(this.layoutFromStore))
      }
    }
  },
  computed: {
    layoutFromStore: {
      get () {
        return this.$store.getters['settings/getLayout']
      },
      set (newLayout) {
        this.$store.commit('settings/SET_LAYOUT', newLayout)
      }
    }
  }
}
</script>

<style>
/* set placeholder background color */
.vue-grid-item.vue-grid-placeholder {
  background: #ff9800;
}
</style>
