import Vue from 'vue'

const ADD_PLOT = (state, plotid) => {
  Vue.set(state.title, plotid, 'Untitled')
  Vue.set(state.description, plotid, null)
  Vue.set(state.fetch_date, plotid, null)
  Vue.set(state.range, plotid, null)
  Vue.set(state.type, plotid, null)
  Vue.set(state.visible_journals, plotid, null)
  Vue.set(state.style, plotid, {})
}

const REMOVE_PLOT = (state, plotid) => {
  Vue.delete(state.title, plotid)
  Vue.delete(state.description, plotid)
  Vue.delete(state.fetch_date, plotid)
  Vue.delete(state.range, plotid)
  Vue.delete(state.type, plotid)
  Vue.delete(state.visible_journals, plotid)
  Vue.delete(state.style, plotid)
}

const SET_TITLE = (state, payload) => {
  Vue.set(state.title, payload.plotid, payload.title)
}

const SET_DESCRIPTION = (state, payload) => {
  Vue.set(state.description, payload.plotid, payload.description)
}

const SET_FETCH_DATE = (state, payload) => {
  Vue.set(state.fetch_date, payload.plotid, payload.fetch_date)
}

const SET_RANGE = (state, payload) => {
  Vue.set(state.range, payload.plotid, payload.range)
}

const SET_TYPE = (state, payload) => {
  Vue.set(state.type, payload.plotid, payload.type)
}

const SET_VISIBLE_JOURNALS = (state, payload) => {
  Vue.set(state.visible_journals, payload.plotid, payload.visible_journals)
}

const SET_STYLE = (state, payload) => {
  Vue.set(state.style, payload.plotid, payload.style)
}

export default {
  ADD_PLOT,
  REMOVE_PLOT,
  SET_TITLE,
  SET_DESCRIPTION,
  SET_FETCH_DATE,
  SET_RANGE,
  SET_TYPE,
  SET_VISIBLE_JOURNALS,
  SET_STYLE
}
