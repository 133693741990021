import '@mdi/font/css/materialdesignicons.min.css'
import 'typeface-roboto/index.css'
import 'material-icons/iconfont/material-icons.css'
import Vue from 'vue'
import './plugins/vuetify'
import App from './App.vue'
import router from './router'
import store from './store/store'
import Vuetify from 'vuetify'
import Api from './api'
import VueSimpleMarkown from 'vue-simple-markdown'
import 'vue-simple-markdown/dist/vue-simple-markdown.css'

Vue.config.productionTip = false

Vue.use(Api)

Vue.use(Vuetify, {
  theme: {
    primary: '#ff9800',
    secondary: '#2196f3',
    accent: '#607d8b',
    error: '#f44336',
    warning: '#ffde3a',
    info: '#009688',
    success: '#8bc34a'
  },
  iconfont: 'mdi'
})

Vue.use(VueSimpleMarkown)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
