<template>
  <div class="bajer-container">
    <v-toolbar
      dense
      flat
      grow
      height="40px"
      color="bright-grey">
      <v-toolbar-title height=40>
        {{ this.getTitle(plotid) }}
        <EditDialog
          title="Plot Title"
          :multiline=false
          :data="getTitle(plotid)"
          v-on:save="saveTitle"
          />
      </v-toolbar-title>
      <div style="display: flex;">
        <v-tabs
          v-model="active"
          right
          height="40px"
          color="#f5f5f5"
        >
          <v-tab :key="this.tabIndexes.plot">Plot</v-tab>
          <v-tab :key="this.tabIndexes.description">Description</v-tab>
          <v-tab :key="this.tabIndexes.settings">Settings</v-tab>
        </v-tabs>
        <PlotDelete v-on:remove="removePlot"/>
      </div>
    </v-toolbar>
    <v-tabs-items
      v-model="active"
      class="content">
      <v-tab-item :key="this.tabIndexes.plot">
        <PlottingWrapper :plotid="plotid" v-on:goToSettings="active=2"/>
      </v-tab-item>
      <v-tab-item :key="this.tabIndexes.description">
        <PlotDescription :plotid="this.plotid"/>
      </v-tab-item>
      <v-tab-item :key="this.tabIndexes.settings">
        <PlotForm :plotid="plotid" v-on:plot="plot"/>
      </v-tab-item>
    </v-tabs-items>
    <PlotStatusBar :plotid="plotid"/>
  </div>
</template>

<script>
import PlotDelete from './PlotDelete'
import PlotForm from './PlotForm'
import PlotDescription from './PlotDescription'
import PlotStatusBar from './PlotStatusBar'
import PlottingWrapper from './PlottingSystem/PlottingWrapper'
import EditDialog from './EditDialog'

import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    PlotDelete,
    PlotForm,
    PlotDescription,
    PlotStatusBar,
    PlottingWrapper,
    EditDialog
  },
  data () {
    return {
      active: null,
      tabIndexes: {
        plot: 0,
        description: 1,
        settings: 2
      }
    }
  },
  props: {
    plotid: String
  },
  computed: {
    ...mapGetters({
      getTitle: 'plot/getTitleById',
      getDescription: 'plot/getDescriptionById',
      getFetchDate: 'plot/getFetchDateById',
      getRange: 'plot/getRangeById',
      getType: 'plot/getTypeById',
      getVisibleJournals: 'plot/getVisibleJournalsById'
    })
  },
  methods: {
    removePlot () {
      this.$store.dispatch('plot/removePlot', this.plotid)
    },
    ...mapActions({
      setPlotProperty: 'plot/setPlotProperty'
    }),
    plot () {
      this.active = 0
    },
    saveTitle (title) {
      this.$store.dispatch('plot/setPlotProperty', {
        plotid: this.plotid,
        mutations: {
          TITLE: title
        }
      })
    }
  }
}
</script>

<style>
.bajer-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #fcfcfc;
  overflow: hidden;
}

.bajer-container:hover,
.bajer-container:focus {
  box-shadow: 0px 0px 2px #999;
}

.bajer-container > .content {
  padding: 7px 5px 3px 5px;
  border-top: 1px solid #ccc;
}

/* Change Vuetify styles */
.bajer-container .v-toolbar__content {
  justify-content: space-between;
}
.bajer-container .v-tabs {
  width: auto;
}
.bajer-container .v-toolbar__title {
  font-weight: 300;
}

.bajer-container .v-window {
  overflow-y: auto;
  height: 100%;
}

.bajer-container .v-window > div,
.bajer-container .v-window > div > div {
  height: inherit;
}
</style>
