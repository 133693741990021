<template>
  <v-container>
    <h1 class="font-weight-light">Preferences</h1>
    <v-divider/>
    <v-layout row wrap>
      <v-flex xs-12 xm-6>
        <JournalColor></JournalColor>
      </v-flex>
      <v-flex xs-12 xm-6>
        <AppState></AppState>
      </v-flex>
    </v-layout>
    <v-divider/>
  </v-container>
</template>

<script>
import JournalColor from '@/components/preferences/JournalColor'
import AppState from '@/components/preferences/AppState'

export default {
  data () {
    return {

    }
  },
  components: {
    JournalColor,
    AppState
  }

}
</script>
