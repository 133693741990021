const getTitleById = (state) => (id) => state.title[id]
const getDescriptionById = (state) => (id) => state.description[id]
const getFetchDateById = (state) => (id) => state.fetch_date[id]
const getRangeById = (state) => (id) => state.range[id]
const getTypeById = (state) => (id) => state.type[id]
const getVisibleJournalsById = (state) => (id) => state.visible_journals[id]
const getStyleById = (state) => (id) => state.style[id]
const getNumberOfPlots = (state) => Object.keys(state.title).length

export default {
  getTitleById,
  getDescriptionById,
  getFetchDateById,
  getRangeById,
  getTypeById,
  getVisibleJournalsById,
  getStyleById,
  getNumberOfPlots
}
