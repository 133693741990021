/**
 * Stores the current dashboard layout
 * @param  {Object} state   Vuex State
 * @param  {Array}  payload Array with layout
 */
const SET_LAYOUT = (state, payload) => {
  state.layout = payload
}
/**
 * Adds a new plot to the layout
 * @param  {Object} state   Vuex State
 * @param  {String} plotid  Unique ID for plot
 */
const ADD_PLOT = (state, plotid) => {
  state.layout.push({ x: 0, y: 0, w: 10, h: 6, i: plotid })
}
/**
 * Removes a plot
 * @param  {Object} state   Vuex State
 * @param  {String} plotid  ID of plot
 */
const REMOVE_PLOT = (state, plotid) => {
  state.layout = state.layout.filter(item => item.i !== plotid)
}
/**
 * Set the colors for each line in the plots
 * @param  {Object} state   Vuex State
 * @type {Object} payload
 */
const SET_PLOT_COLORS = (state, payload) => {
  state.plotColors = payload
}

export default {
  SET_LAYOUT,
  ADD_PLOT,
  REMOVE_PLOT,
  SET_PLOT_COLORS
}
