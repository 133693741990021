<template>
  <v-layout row>
    <v-flex xs11>
      <vue-simple-markdown v-if="getDescriptionById(this.plotid)" :source="getDescriptionById(this.plotid)"></vue-simple-markdown>
      <p v-else>No Description added yet</p>
    </v-flex>
    <v-flex xs1>
      <EditDialog
        :multiline="true"
        :width="600"
        title="Description"
        v-on:save="saveDescription"
        :data="getDescriptionById(this.plotid)"
        />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import EditDialog from './EditDialog'

export default {
  components: {
    EditDialog
  },
  data () {
    return {
    }
  },
  props: {
    plotid: String
  },
  computed: {
    ...mapGetters({
      getDescriptionById: 'plot/getDescriptionById'
    })
  },
  methods: {
    saveDescription (description) {
      this.$store.dispatch('plot/setPlotProperty', {
        plotid: this.plotid,
        mutations: {
          DESCRIPTION: description
        }
      })
    }
  }
}
</script>
