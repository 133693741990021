<template>
  <div>
    <h2 class="font-weight-light">Plot Colors</h2>
    <p>Change the color for each Journal in the plots.</p>
    <div v-for="(value, key) in colors" v-bind:key="key" class="journal-color-picker">
        <ColorPicker v-model="colors[key]"></ColorPicker><span>{{ key }}</span>
    </div>
      <v-btn small @click="reset" color="secondary">Restore Defaults</v-btn>
      <v-btn small @click="cancel()">Cancel</v-btn>
      <v-btn small @click="save()" color="success">Save</v-btn>
  </div>
</template>

<script>
import ColorPicker from '@/components/basics/ColorPicker'
import { mapGetters } from 'vuex'
import JournalColors from '@/plotColors'

export default {
  data () {
    return {
      colors: this.PlotColors
    }
  },
  components: {
    ColorPicker
  },
  computed: {
    ...mapGetters({
      PlotColors: 'settings/getPlotColorsPlain'
    })
  },
  watch: {
    PlotColors () {
      this.setColorsFromVuex()
    }
  },
  methods: {
    setColorsFromVuex () {
      this.colors = JSON.parse(JSON.stringify(this.PlotColors))
    },
    cancel () {
      this.setColorsFromVuex()
    },
    save () {
      this.$store.commit('settings/SET_PLOT_COLORS', this.colors)
    },
    reset () {
      this.$store.commit('settings/SET_PLOT_COLORS', JournalColors(this.$store.getters['runtime/getBibstem']))
    }
  },
  activated () {
    if (!this.colors) {
      this.setColorsFromVuex()
    }
  }
}
</script>

<style>
.journal-color-picker {
  display: flex;
  align-items: center;
  margin: 5px;
}
.journal-color-picker > span {
  margin-left: 10px;
}
</style>
